.selected_ws {
  background-color: #444040;
  color: white;
  cursor: pointer;
  font-weight: bold;
}

.not_selected_ws {
  background-color: #ffffff;
  font-size: 8px;
  background-color: #eee;
}

.grid {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: minmax(0, 1fr);
  gap: 5px;
  justify-content: center;
}

@media screen and (max-width: 949px) {
  .h1Margin {
    margin-bottom: 30px;
  }
}

@media (max-width: 650px) {
  .grid {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (max-width: 500px) {
  .grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 350px) {
  .grid {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.integer-part {
  text-align: right;
  padding-right: 0;
}

.decimal-part {
  padding-left: 0;
  width: 100px;
}
.totalContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}

.resetForm {
  width: 500px;
}

.input {
  width: 100%;
}

.search-papers-form {
  width: 38rem;
}

@media (max-width: 800px) {
  .resetForm {
    width: 250px;
  }

  .input {
    width: 150px;
  }
  .search-papers-form {
    width: 100%;
    padding: 0;

    form {
      width: 100%;
    }
  }
}

@media (max-width: 428px) {
  .tags-wrapper {
    flex-direction: column;
  }
}

.integer-part {
  text-align: right;
  padding-right: 0;
}

.decimal-part {
  padding-left: 0;
  width: 100px;
}
.totalContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}

.resetForm {
  width: 500px;
}

.input{
  width: 100%;
}

@media (max-width: 600px) {
  .resetForm {
    width: 250px;
  }

  .input{
    width: 150px;
  }
  
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap");

h1,
h2,
h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.html {
  height: 100%;
}
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px 12px 12px 12px;
  text-align: center;
  height: 45px;
  background-color: #ff9900;
  color: white;
}

.App {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.error {
  color: red;
  font-size: 12px;
}

.nav {
  height: 60px;
}

.status {
  background-color: lightgray;
  padding: 1em;
}

.maincnt {
  flex: 1 1 0;
  overflow-y: auto;
}
.main {
  max-width: 1152px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.stepper {
  max-width: 1152px;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  margin: 0 auto;
}
.sectionTitle {
  font-family: "OpenSans", sans-serif;
  font-size: 1.6em;
  font-weight: 700;
}

.lora {
  font-family: "Lora", serif;
}

.addcart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 33px;
  width: 54px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  border: 1px solid gray;
}

.addcart.disabled {
  border: 1px solid lightgray;
  color:lightgray;
}


.summary-menu {
  .selected {
    color: #000;
  }
}

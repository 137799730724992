.reg_container {
  display: flex;
  margin-bottom: 80px;
  width: 100%;
}

.flex-container {
  display: flex;
  gap: 40px;
  height: 132px;

}

.basis1 {
  flex-basis: calc(33.33% - 10px);
}

.basis2 {
  flex-basis: calc(66.66% - 10px);
}

@media screen and (max-width: 949px) {
  .flex-container {
    flex-direction: column;
    height: auto;
  }
  .basis1 {
    flex-basis: 100%;
  }
  .h1Margin{
    margin-bottom:30px ;
  }
}

.navbar {
  background: #ffffff;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  color: black;
}

.boxed {
  max-width: 1152px;
}
#ecmlLogo{
    height:37px ;
    width: 63px;
}
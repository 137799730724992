.integer-part {
  text-align: right;
  padding-right: 0;
}

.decimal-part {
  padding-left: 0;
  width: 100px;
}
.totalContainer {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
}
.cartInfo {
  width: 500px;
}

@media(max-width: 600px) {
  .cartInfo {
    width: 250px;
  }
}
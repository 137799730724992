.stepper_box {
  align-items: center;
  background-color: #444040;
  padding: 0.5em 1em;
  margin: 0em 0em;
  border-radius: 5px;
  color: white;
  width: 100%;
}

.stepper_container {
  margin: 1em 0px 0px 0px;
}

@media screen and (max-width: 949px) {
  .stepper_box {
    overflow-x: scroll;
  }
}

.summary-page-content {
  min-height: 31rem;
}

.billing {
  .summary-card {
    .button {
      height: max-content;
      font-size: 13px;
      margin-left: 0;
      background-color: #000;
      padding: 0.5rem 1rem;

      @media screen and (min-width: 640px) {
      }
    }

    .history-title {
      margin-top: 2.5rem;
    }
  }
}

.summary-menu {
  @media screen and (min-width: 1024px) {
  }

  @media screen and (min-width: 640px) {
  }
}

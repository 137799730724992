.side-panel {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background: #444040;
  padding:2em;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  
  
}

.side-panel.open {
  transform: translateX(0);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1000;
}